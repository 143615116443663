import { SafeDynamicImport } from '../../core/components/DynamicImports/SafeDynamicImport';
import { RouteDescriptor } from '../../core/navigation/types';

export const notificationSettingRoutes: RouteDescriptor[] = [
    {
        path: '/notification-setting',
        component: SafeDynamicImport(
            () => import(/* webpackChunkName: "notification-setting"*/ 'app/features/yny-notification-setting/DataPointThresholds'),
        )
    }
];
